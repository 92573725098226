<script setup lang="ts">
  interface ModalData {
    title: string
    platform: string
    mainText: string
    bottomText: string
    img: number
    url?: string
  }

  const awards = [
    {
      image: 0,
      textBold: 'Лучший медцентр 2023',
      textGray: 'по версии 2ГИС',
      modalData: {
        title: 'Лучший медицинский <br> центр в 2023 году',
        platform: 'по версии 2ГИС',
        mainText: 'Ист Клиник заслужил признание благодаря высокому уровню доверия пациентов. Нас выбирают за качество медицинских услуг и рекомендуют близким.',
        bottomText: 'Эксперты Премии — все пользователи 2ГИС. Поэтому лучшими становятся компании, которые они любят, хвалят, советуют друзьям',
        img: 1
      }
    },
    {
      image: 5,
      textBold: 'Топ 30 клиник Москвы',
      textGray: 'по версии ПроДокторов',
      modalData: {
        title: 'Топ 30 клиник Москвы <br> 2023 • 2022 • 2021',
        platform: 'по версии ПроДокторов',
        mainText: 'Ист Клиник вошел в ТОП-10 частных клиник Московской области и ТОП-30 частных клиник Москвы по мнению пациентов.',
        bottomText: 'Премия ПроДокторов — это общенациональная награда, которая присуждается лучшим врачам и клиникам на основании отзывов пользователей портала prodoctorov.ru.',
        img: 2,
        url: 'https://award.prodoctorov.ru/awards/2023/moskva/lpus/'
      }
    },
    {
      image: 1,
      textBold: 'Хорошее место 2023',
      textGray: 'по версии Яндекса',
      modalData: {
        title: 'Хорошее место <br> 2023 • 2022 • 2021',
        platform: 'по версии Яндекса',
        mainText: 'Ист Клиник три года подряд получает награду «Хорошее место» по версии Яндекса, подтверждая свой статус медицинского центра, которому доверяют пациенты.',
        bottomText: 'Эта награда вручается компаниям с высоким рейтингом на Яндекс Картах, что говорит о том, что нашу клинику знают, ценят и рекомендуют для посещения.',
        img: 3
      }
    },
    {
      image: 4,
      textBold: 'Лучшая клиника 2023',
      textGray: 'по версии НаПоправку',
      modalData: {
        title: 'Выбор пациентов <br> 2023 • 2022 • 2021',
        platform: 'по версии НаПоправку',
        mainText: 'Ист Клиник трижды признан одной из «Лучших клиник Москвы», что говорит о неизменном доверии наших пациентов и их выборе на протяжении нескольких лет.',
        bottomText: 'НаПоправку ежегодно выделяет медицинские центры, о которых хорошо отзываются пользователи.',
        img: 4
      }
    },
    {
      image: 3,
      textBold: 'Выбор пациентов 2022',
      textGray: 'по версии СберЗдоровья',
      modalData: {
        title: 'Выбор пациентов <br> 2022 • 2021 • 2020',
        platform: 'по версии СберЗдоровья',
        mainText: 'Ист Клиник стал победителем ежегодной независимой премии от СберЗдоровья.',
        bottomText: 'СберЗдоровье определяет победителей в нескольких номинациях на основе более 170 000 отзывов пользователей о частных и государственных медицинских центрах по различным направлениям.',
        img: 5
      }
    }
  ];

  const currentAward = ref<ModalData>();

  const showModal = ref(false);

  const openModal = (award: ModalData) => {
    currentAward.value = award;
    showModal.value = true;
  };

  const closeModal = () => {
    showModal.value = false;
  };
</script>

<template>
  <ScrollCarousel class="awards-carousel">
    <AwardsCarouselLayoutAwardItem
      v-for="award in awards"
      :key="award.image"
      v-bind="award"
      @click="openModal(award.modalData)"
    />
    <AwardsCarouselLayoutModal v-if="showModal" :award="currentAward" @close="closeModal" />
  </ScrollCarousel>
</template>

<style scoped lang="scss">
.awards-carousel {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
